<template>
  <section class="not-found-page section-fill-screen-min-height-with-header">
    <div class="logo-container">
      <img :src="require('@/assets/img/neuco-white-logo.svg')" alt="NEUCO Logo Branco">
    </div>
    <div class="icon-container">
      <svg width="1080" height="1096" viewBox="0 0 1080 1096" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M963.176 1085.29C1021.66 1085.29 1069.06 1037.86 1069.06 979.338V10.9352H205.045L208.928 562.59H10.9352V967.684C10.9352 996.902 21.8887 1026.27 39.5973 1048.35C57.3048 1070.42 81.8503 1085.29 109.059 1085.29L963.176 1085.29ZM963.176 1085.29C1020.13 1085.29 1066.36 1035.13 1066.94 978.273V13.0647H207.19L211.065 563.647L214.937 978.274C214.393 1029.06 174.967 1073.4 129.057 1083.17M13.0647 564.719H208.945L212.818 979.338C212.818 1036.65 162.102 1083.17 109.059 1083.17C82.6633 1083.17 58.6793 1068.73 41.2584 1047.01C23.8386 1025.3 13.0647 996.395 13.0647 967.684V564.719Z" stroke="black" stroke-width="20"/>
        <path d="M11.2471 656.139L104.454 562.932M11.2471 702.758L151.058 562.947M11.2471 741.607L189.894 562.96M11.2524 780.461L210.357 581.357M11.2471 819.305L213.196 617.356M11.2471 858.154L213.196 656.205M11.2471 897.003L213.196 695.054M11.2471 935.851L213.196 733.903M11.2471 974.7L213.196 772.752M19.0121 1005.78L213.13 811.535M26.7937 1036.88L212.273 851.274M42.3062 1060.17L213.13 889.232M65.6003 1075.71L213.13 928.081M98 1084.5L214 973.5" stroke="black" stroke-width="10"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M470.143 781.181L384.706 703.511L386.138 701.935L471.575 779.605L470.143 781.181Z" stroke="black" stroke-width="20"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M384.706 788.978L470.118 695.741L471.687 697.18L386.275 790.418L384.706 788.978Z" stroke="black" stroke-width="20"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M912.731 781.181L827.294 703.511L828.727 701.935L914.164 779.605L912.731 781.181Z" stroke="black" stroke-width="20"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M827.294 788.978L912.706 695.741L914.275 697.18L828.863 790.418L827.294 788.978Z" stroke="black" stroke-width="20"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M660.353 797.813C620.253 797.813 587.653 832.07 587.653 874.446H585.523C585.523 830.999 618.974 795.683 660.353 795.683C701.731 795.683 735.182 830.999 735.182 874.446H733.053C733.053 832.07 700.453 797.813 660.353 797.813Z" stroke="black" stroke-width="20"/>
      </svg>
    </div>
    <div class="text-container">
      <h1>404...</h1>
      <p>Acho que você chegou ao limite do universo.</p>
      <p>A página que você requisitou não foi encontrada.</p>
      <a @click.prevent="goToHome()" class="btn bg-secondary text-white btn--hover-shadow" href="/">
        Retornar à home
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFound404',
  methods: {
    goToHome() {
      this.$router.push('/');
    },
  }
}
</script>

<style scoped>
.not-found-page {
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  row-gap: 1rem;
  align-items: center;
  justify-items: center;
  padding: 0rem 0rem 10rem 0rem;

  background-color: var(--primary-color);
  background-image: linear-gradient(to bottom right, var(--primary-color) 0%, var(--primary-color-400) 100%);
}

.logo-container {
  padding: 1rem;
  text-align: center;
  height: 100%;
  max-width: 500px;
}

.logo-container img {
  height: 100%;
  max-width: 100%;
}

.icon-container {
  padding: 1rem;
  text-align: center;
  height: 100%;
}

.icon-container svg {
  height: 100%;
  max-width: 100%;
}

.icon-container svg path {
  stroke: white;
}

.text-container {
  padding: 0rem 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
  color: white;
  text-align: center;
}

.text-container h1 {
  font-size: 2.125rem;
}

.text-container p {
  font-size: 1.125rem;
}

.text-container a {
  margin-top: 1rem;
  text-align: center;
  text-decoration: none;
}
</style>
